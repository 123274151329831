<template>
  <div class="checkbox" :class="{ disabled }">
    <FormKit
      :id="id"
      v-model="value"
      :label="t(label)"
      type="checkbox"
      :name="name ?? id"
      :inner-class="`w-[22px] h-[22px] mr-xs relative innerCheckbox overflow-hidden shrink-0 cursor-pointer ${innerClass}`"
      :input-class="
        disabled && hasWhiteBg
          ? 'noGlobal bg-background-base cursor-no-drop'
          : 'noGlobal'
      "
      :wrapper-class="`flex items-center ${wrapperClass}`"
      :disabled="disabled"
    >
      <template v-if="$slots.label" #label>
        <slot name="label"></slot>
      </template>
    </FormKit>
  </div>
</template>
<script setup lang="ts">
import { FormKit } from '@formkit/vue';

defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  id: {
    type: String,
    required: false,
    default: undefined,
  },
  name: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hasWhiteBg: {
    type: Boolean,
    default: true,
  },
  wrapperClass: {
    type: String,
    required: false,
    default: '',
  },
  innerClass: {
    type: String,
    required: false,
    default: '',
  },
});

const { t } = useTrans();
const value = defineModel<boolean>();
</script>
<style lang="postcss" scoped>
.checkbox.disabled {
  @apply opacity-50;
}

.checkbox {
  :deep(input) {
    @apply w-[22px] h-[22px] border-border-light border-1 rounded-alt-xs absolute top-0 left-0 cursor-pointer;
  }
  :deep(input:focus) {
    @apply outline-none ring-0;
    --tw-shadow: none;
    --tw-shadow-colored: none;
    --tw-ring: none;
  }
  :deep(input:checked) {
    @apply bg-primary-base border-border-light border-1;
  }
}
</style>
